.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      .MuiDialogTitle-root {
        // background: var(--dialog-header-background);
        // color: var(--ion-text-light);
        background: var(--ion-text-light);
        color: var(--dialog-header-background);
        text-align: center;
        height: 80px;
        .MuiTypography-root {
          color: var(--dialog-header-background);
        }
      }
      .MuiIconButton-root {
        position: absolute;
        top: 8px;
        right: 8px;
        opacity: 1;
        color: var(--dialog-header-background);
      }

      .light-bg-title {
        background: var(--ion-text-light);
        color: var(--dialog-header-background);
        text-align: center;
        height: 80px;
        .MuiTypography-root {
          color: var(--dialog-header-background);
        }
      }

      .MuiDialogContent-root {
        background: #fff;
        padding: 0px 28px !important;

        .MuiButton-containedPrimary {
          background-color: var(--ion-color-primary);
          color: var(--ion-color-primary-contrast);
          text-transform: none;
          font-weight: 600;
        }
        .MuiButton-containedPrimary:hover {
          background-color: var(--ion-color-primary-shade);
        }
      }
    }
  }
}
